export const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT';
export const SET_NAVBAR_ACTIVEITEM = 'SET_NAVBAR_ACTIVEITEM';
export const SET_PRODUCT_DETAIL = "SET_PRODUCT_DETAIL";

export const ADD_CART_ITEM = "ADD_CART_ITEM";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const EMPTY_CART = "EMPTY_CART"
export const SAVE_SHIPPING_ADDRESS = "SAVE_SHIPPING_ADDRESS";


// REQUEST FIREBASE PRODUCTS
export const BEGIN_PRODUCTS_REQUEST = "BEGIN_PRODUCTS_REQUEST";
export const SUCCESS_PRODUCTS_REQUEST = "SUCCESS_PRODUCTS_REQUEST";
export const FAIL_PRODUCTS_REQUEST = "FAIL_PRODUCTS_REQUEST";

// REQUEST FIREBASE LOGIN
export const BEGIN_LOGIN_REQUEST = "BEGIN_LOGIN_REQUEST";
export const SUCCESS_LOGIN_REQUEST = "SUCCESS_LOGIN_REQUEST";
export const FAIL_LOGIN_REQUEST = "FAIL_LOGIN_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

// REQUEST FIREBASE REGISTER
export const BEGIN_REGISTER_REQUEST = "BEGIN_REGISTER_REQUEST";
export const SUCCESS_REGISTER_REQUEST = "SUCCESS_REGISTER_REQUEST";
export const FAIL_REGISTER_REQUEST = "FAIL_REGISTER_REQUEST";

// CREATE ORDER
export const BEGIN_ORDER_CREATE = 'BEGIN_ORDER_CREATE';
export const SUCCESS_ORDER_CREATE = 'SUCCESS_ORDER_CREATE';
export const FAIL_ORDER_CREATE = 'FAIL_ORDER_CREATE';
export const RESET_ORDER = 'RESET_ORDER';

// PAY ORDER
export const BEGIN_ORDER_PAY = 'BEGIN_ORDER_PAY';
export const SUCCESS_ORDER_PAY = 'SUCCESS_ORDER_PAY';
export const FAIL_ORDER_PAY = 'FAIL_ORDER_PAY';
export const RESET_ORDER_PAY = 'RESET_ORDER_PAY';

// GET ORDER DETAIL
export const BEGIN_ORDER_DETAIL = 'BEGIN_ORDER_DETAIL';
export const SUCCESS_ORDER_DETAIL = 'SUCCESS_ORDER_DETAIL';
export const FAIL_ORDER_DETAIL = 'FAIL_ORDER_DETAIL';

//GET ARTIST MOMENT
export const BEGIN_MOMENT_REQUEST = 'BEGIN_MOMENT_REQUEST';
export const SUCCESS_MOMENT_REQUEST = 'SUCCESS_MOMENT_REQUEST';
export const FAIL_MOMENT_REQUEST = 'FAIL_MOMENT_REQUEST';

export const SET_MOMENT_CONTENT = 'SET_MOMENT_CONTENT';
export const SET_MOMENT_DETAIL = 'SET_MOMENT_DETAIL';

//GET ARTIST POST
export const BEGIN_ARTISTPOST_REQUEST = 'BEGIN_ARTISTPOST_REQUEST';
export const SUCCESS_ARTISTPOST_REQUEST = 'SUCCESS_ARTISTPOST_REQUEST';
export const FAIL_ARTISTPOST_REQUEST = 'FAIL_ARTISTPOST_REQUEST';
export const SET_ARTISTPOST_CONTENT = 'SET_ARTISTPOST_CONTENT';

//GET USER INFO
export const BEGIN_USERINFO_REQUEST  = 'BEGIN_USERINFO_REQUEST';
export const SUCCESS_USERINFO_REQUEST  = 'SUCCCESS_USERINFO_REQUEST';
export const FAIL_USERINFO_REQUEST  = 'FAIL_USERINFO_REQUEST';
export const SET_USERINFO_CONTENT  = 'SET_USERINFO_CONTENT';
//這裏要寫一個update user info 的
export const BEGIN_UPDATE_USERINFO = "BEGIN_UPDATE_USERINFO";
export const SUCCESS_UPDATE_USERINFO = "SUCCESS_UPDATE_USERINFO";
export const FAIL_UPDATE_USERINFO = "FAIL_UPDATE_USERINFO";

//GET REPLY USER INFO
export const BEGIN_REPLYUSERINFO_REQUEST  = 'BEGIN_REPLYUSERINFO_REQUEST';
export const SUCCESS_REPLYUSERINFO_REQUEST  = 'SUCCCESS_REPLYUSERINFO_REQUEST';
export const FAIL_REPLYUSERINFO_REQUEST  = 'FAIL_REPLYUSERINFO_REQUEST';
export const SET_REPLYUSERINFO_CONTENT  = 'SET_REPLYUSERINFO_CONTENT';

//CREATE FAN POST
export const BEGIN_FANPOST_CREATE = 'BEGIN_FANPOST_CREATE';
export const SUCCESS_FANPOST_CREATE = 'SUCCESS_FANPOST_CREATE';
export const FAIL_FANPOST_CREATE = 'FAIL_FANPOST_CREATE';

//GET FAN POST
export const BEGIN_FANPOST_REQUEST = 'BEGIN_FANPOST_REQUEST';
export const SUCCESS_FANPOST_REQUEST = 'SUCCESS_FANPOST_REQUEST';
export const FAIL_FANPOST_REQUEST = 'FAIL_FANPOST_REQUEST';
export const SET_FANPOST_CONTENT = 'SET_FANPOST_CONTENT';